import './styles.sass';

import { navigate } from 'gatsby';
import ApiService from 'utils/apiHelper';
import BlueX from './blue-x.svg';
import Button from 'react-bootstrap/Button';
import CharlesMeta from 'components/common/meta/CharlesMeta';
import { Layout } from 'components/common';
import { Link } from "gatsby";
import Image from 'components/common/utils/Image';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import data from './data.json'

const EMAIL_REGEX = /^[a-z0-9]+([_|\.|-]{1}[a-z0-9]+)*@[a-z0-9]+([_|\.|-]{1}[a-z0-9]+)*[\.]{1}[a-z]{2,6}$/i

class LivresBlancs extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showLivresBlancsPopup: false,
      checkBoxChecked: false,
      email: '',
      invalidEmail: false,
      submitNotChecked: false,
      whitePaperId: ''
    };
  }

  handleClick = (whitePaperId) => {
    this.setState({
      showLivresBlancsPopup: true,
      whitePaperId: whitePaperId
    });
  }

  handleClose = () => {
    this.setState({
      showLivresBlancsPopup: false,
      invalidEmail: false
    });
  }

  handleCheck = () => {
    this.setState({ checkBoxChecked: !this.state.checkBoxChecked });

    if (this.state.submitNotChecked == true) {
      this.setState({ submitNotChecked: false })
    }
  }

  handleChange = (e) => {
    this.setState({ email: e.target.value })

    if (EMAIL_REGEX.test(e.target.value) == true) {
      this.setState({ invalidEmail: false })
    }
  }

  handleSubmit = () => {
    if (EMAIL_REGEX.test(this.state.email) == false || this.state.email === '') {
      this.setState({ invalidEmail: true })
      event.preventDefault();
    } else if ((this.state.checkBoxChecked) == false) {
      this.setState({ submitNotChecked: true })
      event.preventDefault();
    }
    else {
      this.setState({ invalidEmail: false });
      this.handleQuery(this.state.email, this.state.whitePaperId);
    }
  }

  handleKeyPress = (event) => {
    if (event.charCode === 13)
      this.handleSubmit()
  }

  handleQuery = (email, whitePaperId) => {
    (async () => {
      await ApiService.subscribeWhitePaper({ email, whitePaperId }).then((response) => {
        window.location.href = response.data.redirectUrl;
      }
      ).catch((response) => {
        console.error("error:", response)
      })
    })();
  }

  render() {
    const myPapers = Object.entries(data)

    return (
      <Layout
        footerCtaPath="/consulter-baisse-de-libido/"
        isBlog
        showLinksInFooter={false}
        showBars
        backgroundBeforeFooter="background-color-pale-yellow"
      >
        <CharlesMeta
          data={{
            title: 'Découvrez les guides de Mia.co à télécharger gratuitement.',
            description:
              'Accédez gratuitement aux guides Mia, la plateforme de téléconsultation spécialisée en sexologie féminine. Consultez une spécialiste par vidéo, messages, ou téléphone.',
          }}
        />
        <div className="livres-blancs-container">
          <div className="livres-blancs">
            <div className="livres-blancs-title">
              <h2>Nos guides</h2>
            </div>
            {myPapers.map((paper, index) =>
              <div> <div className="livres-blancs-div" key={paper[1].slug}>
                <Image
                  filename={paper[1].image}
                  className="livres-blancs-image"
                  layout="constrained"
                  alt={paper[1].alt}
                />
                <div className="livres-blancs-infos">
                  <h3 className="ebook-title">{paper[1].ebookTitle}</h3>
                  <h4 className="ebook-subtitle">Par <a target="_blank" rel="norefer noopener" href={paper[1].subtitleLink1}>{paper[1].subtitle1}</a> et <a target="_blank" rel="norefer noopener" href={paper[1].subtitleLink2}>{paper[1].subtitle2}</a></h4>
                  <p>{paper[1].ebookDescription}</p>
                  <Button
                    className="cta-livres-blancs"
                    onClick={() => this.handleClick(paper[1].slug)}
                    type="reset"
                  >
                    Obtenir le guide
                  </Button>
                </div>
              </div>
              </div>
            )}
            {
              this.state.whitePaperId && (
              <Modal
                className="modal-ebook"
                centered
                show={this.state.showLivresBlancsPopup}
                onHide={this.handleClose}
                size="lg"
              >
                <div className="popup-livres-blancs-wrapper">
                  <div className="popup-x-div ">
                    <img
                      src={BlueX}
                      id="popup-x"
                      width={25}
                      onClick={this.handleClose}
                    />
                  </div>
                  <div className="livres-blancs-popup container">
                    <div className="row align-items-center justify-content-center">
                      <div
                        className="col "
                        id="popup-col-1"
                      >
                        <h2>Récupérez votre ebook par <span className="nbsp">e-mail</span></h2>
                        <div id="popup-chapeau">{data[this.state.whitePaperId].popupChapeau}</div>
                        <input
                          type="email"
                          id="popup-ebook-form"
                          placeholder="Adresse e-mail "
                          name="emailSubmit"
                          onChange={this.handleChange}
                          onKeyPress={this.handleKeyPress}
                        />
                        {this.state.invalidEmail &&
                          <div className="submit-invalide">l'e-mail saisi est invalide</div>
                        }
                        <Button
                          className="btn-orange d-block"
                          id="btn-popup-ebook"
                          onClick={this.handleSubmit}
                        >
                          {' '}
                          Je récupère mon e-book
                        </Button>
                        {this.state.submitNotChecked &&
                          <div className="submit-invalide">Vous devez accepter les conditions pour recevoir votre ebook</div>
                        }
                        <div className="acceptation-ebook">
                          <div id="checkbox-container"><div
                            id="ebook-checkbox"
                            className={this.state.checkBoxChecked ? 'ebook-checked' : 'ebook-notchecked'}
                            onClick={this.handleCheck}
                          />
                          </div>
                          <p className="acceptation-ebook-text">En complétant votre adresse email, vous acceptez que nous la transmettions à la personne qui nous a aidée à écrire ce e-book.
                            Pour en savoir plus, consultez nos <Link className="CGUV-link" to="/conditions-generales/">CGUV</Link> et notre<Link className="CGUV-link" to="/politique-de-confidentialite/"> politique de confidentialité.</Link></p>
                        </div>
                      </div>
                      <div className="col livres-blancs-image-popup-div">
                        <Image
                          filename={data[this.state.whitePaperId].image}
                          className="livres-blancs-image-popup"
                          layout="constrained"
                          alt={data[this.state.whitePaperId].alt}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </div>
      </Layout>
    )
  }
}
export default LivresBlancs;
